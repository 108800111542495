import get from 'lodash/get'

export const SET_APPLICATION_DATA = 'applicationData//SET_APPLICATION_DATA'
export const RESET_APPLICATION_DATA = 'applicationData//RESET_APPLICATION_DATA'

export function setStepData(step, applicationData) {
  return {
    type: SET_APPLICATION_DATA,
    payload: {
      step,
      applicationData,
    },
  }
}

export function resetFormData() {
  return {
    type: RESET_APPLICATION_DATA,
  }
}

export const getStepData = (state, step) =>
  get(state.applicationData.data, `${step}`)

const initialState = {
  data: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_APPLICATION_DATA:
      return {
        ...state,
        data: {},
      }
    case SET_APPLICATION_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.step]: action.payload.applicationData,
        },
      }
    default:
      return state
  }
}
