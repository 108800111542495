/* eslint-disable max-len */
export const getBrandName = () => {
  return process.env.REACT_APP_WEBSITE_BRAND === 'vw5'
    ? 'vw'
    : process.env.REACT_APP_WEBSITE_BRAND
}
export const ENTRY_POINTS = {
  HEYCAR: 'heycar',
  ONESHOP: 'oneshop',
  VTP: 'vtp',
  ACS: 'acs',
}

const skodaVTPEntryPoint =
  process.env.REACT_APP_STAGING_ENV === 'prod' ? 'stock-locator' : 'vtp'

const checkForStockLocatorEntryPoint = () =>
  window.location.href.indexOf(skodaVTPEntryPoint) >= 0

const checkForAcsEntryPoint = () => window.location.href.indexOf('acs') >= 0

export const getEntryPoint = () => {
  if (checkForStockLocatorEntryPoint()) return ENTRY_POINTS.VTP

  if (checkForAcsEntryPoint()) return ENTRY_POINTS.ACS

  return window.location.href.indexOf('oneshop') >= 0
    ? ENTRY_POINTS.ONESHOP
    : ENTRY_POINTS.HEYCAR
}
export const CONNECTION_TIMEOUT = 35000

export const setAnalitycsDefaults = () => {
  return {
    core: {
      stagingEnvironment: `${process.env.REACT_APP_STAGING_ENV}`,
      dataLayerVersion: '2.2',
      pageInfo: {
        pageName: null,
        intendedCustomerDeviceType: 'all',
        version: '2.0',
        releaseDate: '2022-08-17',
        language: 'en',
        market: 'UK',
        publisher: 'DU',
      },
      category: {
        primaryCategory: 'financing',
        secondaryCategory: 'AutoCredit',
        siteType: 'checkoutSite',
        inventoryType: 'Asset-based products',
        maturityLevel: 'Online contract',
        contractType: 'New',
      },
      attributes: {
        journeyType: 'customer-facing-product-journey',
        viewChange: null,
        brand: getBrandName(),
      },
    },
    error: {
      errorCode: null,
      errorMessage: null,
      errorCausingURL: null,
    },
    /*
    This structure is filled as soon as the user provides information regarding any product. Please keep in mind to update the event structure as well. For example, the successful conversion should be indicated with EventAction = Success, EventType = Sale

    Please note that this structure is an array structure. We expect one entry for each contract sold. If the customer can bundle his contract (i.e. get a leasing contract, a service and maintenance package, and a tire package, this array should have three entries.
    */
    // @Developers: Do not fill this but keep the values empty for further releases!
    product: [
      {
        name: 'AutoCredit',
        category: 'Finance',
        attributes: {
          typeOfSale: 'Online sales',
          recurringPayment: null,
          durationUnit: null,
          duration: null,
          paymentType: null,
          balloonPayment: null,
          contractAmount: null,
          netLoanAmount: null,
          grossLoanAmount: null,
          downPaymentAmount: null,
          nominalInterestRate: null,
          effectiveInterestRate: null,
          yearlyMileage: null,
          excessMileage: null,
          mileageUnit: null,
          addOns: [
            {
              name: null,
              additionalInformation: null,
            },
          ],
        },
        vehicleModel: [
          {
            manufacturer: null,
            name: null,
            modelVariation: null,
            currentMileage: null,
            currentMileageUnit: null,
            initialRegistrationDate: null,
            colorExterior: null,
            equity: null,
            condition: null,
            engine: {
              fuelType: null,
              transmission: null,
              enginePower: null,
            },
          },
        ],
      },
    ],
    dealerData: {
      companyName: null,
      address: {
        street: null,
        zipCode: null,
        city: null,
      },
    },
    customerData: {
      loginStatus: null,
      yearOfBirth: null,
      gender: null,
      address: {
        zipCode: null,
      },
    },
    dataPrivacyStatement: {
      allowPostalAds: null,
      allowPhoneAds: null,
      allowElectronicAds: null,
      allowMailAds: null,
      allowElectronicInvoices: null,
    },
    event: [
      {
        eventInfo: {
          eventType: null,
          eventAction: null,
          eventTargetURL: null,
          linkInformation: null,
        },
      },
    ],
  }
}
