import { getEntryPoint } from '../../../config'

export const SET = 'session//SET'

export function setSession(data) {
  return {
    type: SET,
    payload: data,
  }
}

export function getSession(store) {
  return store.session.data
}

const initialState = {
  data: { token: '', cid: '', entryPoint: getEntryPoint() },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        data: action.payload,
      }

    default:
      return state
  }
}
