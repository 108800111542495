import { createStore, combineReducers } from 'redux'

import formReducer from './features/form.redux'
import dataModelsReducer from './features/data-models.redux'
import storefrontReducer from './features/storefront.redux'
import addressReducer from './features/address.redux'
import creditdecisionReducer from './features/credit-decision.redux'
import sessionReducer from './features/session.redux'
import applicationDataReducer from './features/applicationData.redux'

/* eslint-disable no-underscore-dangle */
const store = createStore(
  combineReducers({
    form: formReducer,
    dataModel: dataModelsReducer,
    storefront: storefrontReducer,
    address: addressReducer,
    creditDecision: creditdecisionReducer,
    session: sessionReducer,
    applicationData: applicationDataReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */

export { store }
