import * as Yup from 'yup'
import { format as fnsFormat, differenceInYears, isFuture } from 'date-fns'

Yup.addMethod(Yup.string, 'dateFormat', function format(
  formats,
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testFormat', message, function testFormat(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        value === fnsFormat(new Date(date[2], date[1] - 1, date[0]), formats)
      )
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'fullAge', function fullAge(
  years,
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        differenceInYears(
          new Date(),
          new Date(date[2], date[1] - 1, date[0])
        ) >= years
      )
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'notInFuture', function notInFuture(
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return !isFuture(new Date(date[2], date[1] - 1, date[0]))
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'minValue', function minValue(
  message,
  minValueParam
) {
  return this.test('testMin', message, function testMin(value) {
    if (!value) {
      return true
    }
    return parseFloat(value.replace(/,/g, '.')) >= minValueParam
  })
})
Yup.addMethod(Yup.string, 'maxValue', function maxValue(
  message,
  maxValueParam
) {
  return this.test('testMax', message, function testMax(value) {
    if (!value) {
      return true
    }
    return parseFloat(value.replace(/,/g, '.')) <= maxValueParam
  })
})
Yup.addMethod(Yup.string, 'atLeastOneOf', function atLeastOneOf(list, message) {
  return this.test('testAtLeastOneOf', message, function testAtLeastOneOf(
    value
  ) {
    if (value === undefined) {
      return list.some(item => this.parent[item] !== undefined)
    }
    return true
  })
})

export default Yup
