export const FETCH_GET_ADDRESS_SUGGESTIONS_PENDING =
  'quote//FETCH_GET_ADDRESS_SUGGESTIONS_PENDING'
export const FETCH_GET_ADDRESS_SUGGESTIONS_DONE =
  'quote//FETCH_GET_ADDRESS_SUGGESTIONS_DONE'
export const FETCH_GET_ADDRESS_SUGGESTIONS_ERROR =
  'quote//FETCH_GET_ADDRESS_SUGGESTIONS_ERROR'

export const FETCH_GET_ADDRESS_PENDING = 'quote//FETCH_GET_ADDRESS_PENDING'
export const FETCH_GET_ADDRESS_DONE = 'quote//FETCH_GET_ADDRESS_DONE'
export const FETCH_GET_ADDRESS_ERROR = 'quote//FETCH_GET_ADDRESS_ERROR'

export function getAddressSuggestionsForDropdown(usage, store) {
  if (
    store.address.usages[usage] &&
    store.address.usages[usage].suggestData &&
    store.address.usages[usage].suggestData.customerData &&
    store.address.usages[usage].suggestData.customerData.addresses
  ) {
    const list = store.address.usages[
      usage
    ].suggestData.customerData.addresses.map(a => ({
      label: a.addressString,
      value: a.addressId,
    }))
    return [{ value: '', label: 'Please select...' }, ...list]
  }
  return []
}

export function getAddressForTextfields(usage, store) {
  if (
    store.address.usages[usage] &&
    store.address.usages[usage].addressData &&
    store.address.usages[usage].addressData.customerData &&
    store.address.usages[usage].addressData.customerData.addresses
  ) {
    const a = store.address.usages[usage].addressData.customerData.addresses[0]
    return a
  }
  return {}
}

export function pendingSuggestions(usage) {
  return {
    type: FETCH_GET_ADDRESS_SUGGESTIONS_PENDING,
    usage,
  }
}

export function doneSuggestions(usage, data) {
  return {
    type: FETCH_GET_ADDRESS_SUGGESTIONS_DONE,
    usage,
    payload: {
      data,
    },
  }
}

export function errorSuggestions(usage, err) {
  return {
    type: FETCH_GET_ADDRESS_ERROR,
    usage,
    payload: {
      err,
    },
  }
}

export function pendingAddress(usage) {
  return {
    type: FETCH_GET_ADDRESS_PENDING,
    usage,
  }
}

export function doneAddress(usage, id, data) {
  return {
    type: FETCH_GET_ADDRESS_DONE,
    usage,
    payload: {
      id,
      data,
    },
  }
}

export function errorAddress(usage, err) {
  return {
    type: FETCH_GET_ADDRESS_SUGGESTIONS_ERROR,
    usage,
    payload: {
      err,
    },
  }
}
const initialState = {
  usages: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_GET_ADDRESS_SUGGESTIONS_PENDING:
      return {
        usages: {
          ...state.usages,
          [action.usage]: {
            suggestData: {},
            addressData: {},
            pending: true,
            selectedId: null,
            error: null,
          },
        },
      }

    case FETCH_GET_ADDRESS_SUGGESTIONS_DONE:
      return {
        usages: {
          ...state.usages,
          [action.usage]: {
            suggestData: action.payload.data,
            addressData: {},
            pending: false,
            selectedId: null,
            error: null,
          },
        },
      }

    case FETCH_GET_ADDRESS_SUGGESTIONS_ERROR:
      return {
        usages: {
          ...state.usages,
          [action.usage]: {
            suggestData: {},
            addressData: {},
            pending: true,
            selectedId: null,
            error: true,
          },
        },
      }

    case FETCH_GET_ADDRESS_PENDING:
      return {
        usages: {
          ...state.usages,
          [action.usage]: {
            suggestData: state.usages[action.usage].suggestData,
            addressData: {},
            pending: true,
            selectedId: null,
            error: null,
          },
        },
      }

    case FETCH_GET_ADDRESS_DONE:
      return {
        usages: {
          ...state.usages,
          [action.usage]: {
            suggestData: state.usages[action.usage].suggestData,
            addressData: action.payload.data,
            pending: false,
            selectedId: action.payload.id,
            error: null,
          },
        },
      }

    case FETCH_GET_ADDRESS_ERROR:
      return {
        usages: {
          ...state.usages,
          [action.usage]: {
            suggestData: state.usages[action.usage].suggestData,
            addressData: {},
            pending: false,
            selectedId: null,
            error: true,
          },
        },
      }

    default:
      return state
  }
}
